import "@/base";

import { medwareFiltersIntegration } from "@medware/sentry-filters";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import App from "@/App.vue";
import { config } from "@/config";
import { pinia } from "@/stores";

const app = createApp(App).use(pinia);

if (config.sentry.enabled) {
  Sentry.init({
    app,
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    integrations: [
      medwareFiltersIntegration(),
      Sentry.extraErrorDataIntegration({
        captureErrorCause: true
      })
    ]
  });
}

// Force preservation of whitespace in templates or adjacent buttons
// will touch each other. Buttons should probably be wrapped in a
// container element and styled instead.
app.config.compilerOptions.whitespace = "preserve";

document.querySelectorAll(".vue-app").forEach((el) => {
  app.mount(el);
});
